import React from "react";
import {Button, Card, Container, Grid, Icon, Table} from "semantic-ui-react";

export default function AdmiralGroupsBrandSelector(props) {
    return (
        <Grid>
            <Grid.Column width={16}>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Container style={{maxHeight: 720, minHeight: 720, overflowY: "auto", overflowX: "hidden"}}>
                            <Button fluid color="green" onClick={() => props.refreshGroups()}>Refresh Groups</Button>
                            {
                                props.brands.length < 1 ?
                                    <Card fluid>
                                        <Card.Content>
                                            <Card.Description>
                                                <h5>No Brands</h5>
                                                {
                                                    !Array.isArray(props.brands) || props.brands.length < 1 ?
                                                        "There are no brands to select." :
                                                        "No brands match your search criteria."
                                                }
                                            </Card.Description>
                                        </Card.Content>
                                    </Card> :
                                    <Table padded>
                                        <Table.Body>
                                            {
                                                props.brands.map(result =>
                                                    <Table.Row key={`${result.id}-list-item`} active={props.selectedBrand === result.id} onClick={() => props.setSelectedBrand(result.id)} width={15}>
                                                        <Table.Cell>
                                                            <h5>{result.text}</h5>
                                                        </Table.Cell>
                                                        <Table.Cell width={1}>
                                                            {
                                                                props.selectedBrand === result.id ? <Icon name="chevron right" /> : ""
                                                            }
                                                        </Table.Cell>
                                                    </Table.Row>)
                                            }
                                        </Table.Body>
                                    </Table>
                            }
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
