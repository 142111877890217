import React from "react";
import {Segment, Grid, Tab, Button} from "semantic-ui-react";
import AdmiralAuditMessageTab from "./AdmiralAuditMessageTab";

export default function AuditSubComponent(props) {
    const copyToClipboard = (messageType, payload) => {
        navigator.clipboard.writeText(payload).then(() => {
            props.toast("Copied", `You have copied the ${messageType} to your clipboard.`, "success");
        });
    }

    return (
        <Grid>
            <Grid.Column width={16}>
                <Tab
                    fluid
                    styled
                    defaultActiveIndex={[0]}
                    exclusive={false}
                    panes={[
                        {
                            menuItem: "Message",
                            render: () =>  <AdmiralAuditMessageTab message={atob(props.message)} status={props.status} id={props.id} toast={props.toast} />
                        },
                        {
                            menuItem: "Context",
                            render: () => (
                                <Segment>
                                    <Button floated="right" onClick={() => copyToClipboard("context", JSON.stringify(props.context, null, 4))}>Copy Context</Button>
                                    <br />
                                    <pre>{JSON.stringify(props.context, null, 4)}</pre>
                                </Segment>
                            )
                        },
                        {
                            menuItem: "Payload",
                            render: () => {
                                let payload;
                                try {
                                    payload = JSON.stringify(JSON.parse(atob(props.payload)), null, 4);
                                } catch (_) {
                                    payload = "";
                                }
                                return (
                                    <Segment>
                                        <Button floated="right" onClick={() => copyToClipboard("payload", JSON.stringify(JSON.parse(atob(props.payload)), null, 4))}>Copy Payload</Button>
                                        <br />
                                        <pre>{payload}</pre>
                                    </Segment>
                                );
                            }
                        }
                    ]}
                />
            </Grid.Column>
        </Grid>
    );
};
