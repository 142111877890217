import React, {useState, useEffect} from "react";
import AdmiralDataProvider from "../../../Services/AdmiralDataProvider";
import {Select, Form, Grid, Button} from "semantic-ui-react";
import DateFnsUtils from "@date-io/date-fns";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import ReactTable from "react-table-v6";
import AuditSubComponent from "./AuditSubComponent";
import ContingentButton from "../../ContingentButton";
import _ from "lodash";

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};
const STATUS_OPTIONS = [
    {key: "all", "text": "All", value: "ALL"},
    {key: "failed", text: "Failed", value: "FAILED"},
    {key: "completed", text: "Completed", value: "COMPLETED"},
    {key: "reprocess-completed", text: "Reprocess Completed", value: "REPROCESS_COMPLETED"},
    {key: "reprocess-failed", text: "Reprocess Failed", value: "REPROCESS_FAILED"},
    {key: "not-processed", text: "Not Processed", value: "NOT_PROCESSED"}
];

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const ONE_WEEK_MS = ONE_DAY_MS * 7;
const NOW_MS = new Date().getTime();
const INITIAL_END_MS = NOW_MS + ONE_WEEK_MS;
const INITIAL_START_MS = NOW_MS - ONE_WEEK_MS;

AdmiralDataProvider.init({baseURL: process.env.ADMIRAL_URL});

export default function AdmiralAuditMasterDetailView(props) {
    const [airingIds, setAiringIds] = useState([]);
    const [airingIdOptions, setAiringIdOptions] = useState([]);
    const [mediaIds, setMediaIds] = useState([]);
    const [mediaIdOptions, setMediaIdOptions] = useState([]);
    const [titleIds, setTitleIds] = useState([]);
    const [titleIdOptions, setTitleIdOptions] = useState([]);
    const [videoIds, setVideoIds] = useState([]);
    const [videoIdOptions, setVideoIdOptions] = useState([]);
    const [showIds, setShowIds] = useState([]);
    const [showIdOptions, setShowIdOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("ALL");
    const [selectedSource, setSelectedSource] = useState("ALL");
    const [selectedBrand, setSelectedBrand] = useState("ALL");
    const [ingestType, setIngestType] = useState("");
    const [selectedProfileType, setSelectedProfileType] = useState("ALL");
    const [start, setStart] = useState(INITIAL_START_MS);
    const [end, setEnd] = useState(INITIAL_END_MS);
    const [civiData, setCiviData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userCanReprocess, setUserCanReprocess] = useState(false);
    const [userCanReprocessSearchCriteria, setUserCanReprocessSearchCriteria] = useState(false);
    const [isSearchCriteriaUpdated, setIsSearchCriteriaUpdated] = useState(false);
    const [mostRecentSearchCriteria, setMostRecentSearchCriteria] = useState({});
    const [searchClicked, setSearchClicked] = useState(false);
    const [profileTypeOptions, setProfileTypeOptions] = useState([]);
    const [sourceOptions, setSourceOptions] = useState([]);
    const [brandOptions, setBrandOptions] = useState([]);
    const [metadata, setMetadata] = useState({});
    const [brandLimits, setBrandLimits] = useState([]);
    const [reprocessableBrands, setReprocessableBrands] = useState([]);
    const [shouldDisableReprocess, setShouldDisableReprocess] = useState(true);

    useEffect(function searchOnInit() {
        if (brandLimits === false || brandLimits.length > 0) {
            setSearchClicked(true);
            new Promise( resolve => {
                resolve(performSearch());
            }).finally(() => {
                setSearchClicked(false);
            });
        }
    }, [JSON.stringify(brandLimits)]);

    useEffect(function updateBrandLimitsAndReprocessableBrands() {
        const updatedBrandLimits = [];
        const updatedReprocessableBrands = [];
        for (const permission of props.userPermissions) {
            if (permission.urn === "urn:all:aspen-power") {
                setBrandLimits(false);
                setReprocessableBrands(false);
                return;
            } else if (permission.service === props.service && permission.module === props.module) {
                if (permission.scope === "all") {
                    setBrandLimits(false);
                    if (permission.role === "editor") {
                        setReprocessableBrands(false);
                    }
                    return;
                }
                updatedBrandLimits.push(permission.scope);
                if (permission.role === "editor") {
                    updatedReprocessableBrands.push(permission.scope);
                }
            }
        }

        setBrandLimits(updatedBrandLimits);
        setReprocessableBrands(updatedReprocessableBrands);
    }, [JSON.stringify(props.userPermissions)]);

    useEffect(function initMetadata() {
        AdmiralDataProvider.getMetadata().then(response => {
            if (response.hasOwnProperty("metadata")) {
                setMetadata(response.metadata);
            } else {
                console.warn("(AdmiralAuditMasterDetailView.initMetadata) response had no metadata key: ", metadata);
                setMetadata({});
            }
        });
    }, []);

    useEffect(function updateOptions() {
        if (metadata.hasOwnProperty("ingest_profiles")) {
            setProfileTypeOptions([{key: "all", text: "All", value: "ALL"}].concat(Object.keys(metadata.ingest_profiles).sort().map(profileType => {
                return {key: profileType, text: metadata.ingest_profiles[profileType], value: profileType};
            })));
        }

        if (metadata.hasOwnProperty("ingest_sources")) {
            setSourceOptions([{key: "all", text: "All", value: "ALL"}].concat(Object.keys(metadata.ingest_sources).sort().map(source => {
                return {key: source, text: metadata.ingest_sources[source], value: source};
            })));
        }

        if (metadata.hasOwnProperty("ingest_brands")) {
            setBrandOptions([{key: "all", text: "All", value: "ALL"}].concat(Object.keys(metadata.ingest_brands).sort().filter(brand => {
                return brandLimits === false || brandLimits.includes(brand);
            }).map(brand => {
                return {key: brand, text: metadata.ingest_brands[brand], value: brand};
            })));
        }
    }, [JSON.stringify(metadata)]);

    useEffect(function updateUserCanReprocess() {
        let updatedUserCanReprocess = false;
        for (const permission of props.permissions) {
            updatedUserCanReprocess ||= permission.urn === "urn:all:aspen-power" || (permission.service === props.service && permission.module === props.module && permission.role === "editor");
            if (updatedUserCanReprocess) {
                break;
            }
        }

        console.log("AdmiralAuditMasterDetailView.updateUserCanReprocess: user can reprocess? ", updatedUserCanReprocess);
        setUserCanReprocess(updatedUserCanReprocess);
    }, [JSON.stringify(props.permissions), props.service, props.module]);

    useEffect(function updateIsReprocessDisabled() {
        const updatedShouldDisableReprocess = isSearchCriteriaUpdated || !searchClicked || !userCanReprocessSearchCriteria || !userCanReprocess;
        console.log(`AdmiralAuditMasterDetailView.updateIsReprocessDisabled: should disable reprocess? ${updatedShouldDisableReprocess}, user can reprocess? ${userCanReprocess}, search criteria updated? ${isSearchCriteriaUpdated}, search clicked? ${searchClicked}, user can reprocess search criteria? ${userCanReprocessSearchCriteria}`)
        setShouldDisableReprocess(updatedShouldDisableReprocess);
    }, [userCanReprocess, userCanReprocessSearchCriteria, isSearchCriteriaUpdated, searchClicked]);

    useEffect(function updateUserCanReprocessSearchCriteria() {
        if (!userCanReprocess) {
            setUserCanReprocessSearchCriteria(false);
            return;
        }

        const brandsContained = new Set();
        let updatedUserCanReprocessSearchCriteria = true;

        if (reprocessableBrands !== false) {
            for (const result of civiData) {
                brandsContained.add(result.brand);
            }

            for (const brand of Array.from(brandsContained)) {
                updatedUserCanReprocessSearchCriteria &&= reprocessableBrands.includes(brand);
                if (!updatedUserCanReprocessSearchCriteria) {
                    break;
                }
            }

            console.log("AdmiralAuditMasterDetailView.updateUserCanReprocessSearchCriteria: brands contained: ", brandsContained, "reprocessable brands? ", reprocessableBrands, "user can reprocess search criteria? ", updatedUserCanReprocessSearchCriteria);

        }

        setUserCanReprocessSearchCriteria(updatedUserCanReprocessSearchCriteria);
    }, [userCanReprocess, JSON.stringify(civiData)]);

    useEffect(function areSearchCriteriaChanged() {
        console.log("most recent search criteria", mostRecentSearchCriteria, airingIds);
        const airingIdUpdated = !mostRecentSearchCriteria.hasOwnProperty("airing_id") ? airingIds.length > 0 : airingIds.includes(mostRecentSearchCriteria.airing_id);
        const mediaIdUpdated = !mostRecentSearchCriteria.hasOwnProperty("media_id") ? mediaIds.length > 0 : mediaIds.includes(mostRecentSearchCriteria.media_id);
        const titleIdUpdated = !mostRecentSearchCriteria.hasOwnProperty("title_id") ? titleIds.length > 0 : titleIds.includes(mostRecentSearchCriteria.title_id);
        const showIdUpdated = !mostRecentSearchCriteria.hasOwnProperty("show_id") ? showIds.length > 0 : showIds.includes(mostRecentSearchCriteria.show_id);
        const videoIdsUpdated = !mostRecentSearchCriteria.hasOwnProperty("video_ids") ? videoIds.length > 0 : videoIds.includes(mostRecentSearchCriteria.video_ids);
        const searchCriteriaHasBrand = mostRecentSearchCriteria.hasOwnProperty("brand") && mostRecentSearchCriteria.brand.hasOwnProperty("values");
        const selectedBrands = brandLimits === false ? selectedBrand === "ALL" ? false : [selectedBrand] : selectedBrand === "ALL" ? brandLimits : [selectedBrand];
        const selectedBrandUpdated = !searchCriteriaHasBrand ? selectedBrand !== "ALL" : JSON.stringify(selectedBrands) !== JSON.stringify(mostRecentSearchCriteria.brand.values);
        const selectedProfileTypeUpdated = !mostRecentSearchCriteria.hasOwnProperty("profile_type") ? selectedProfileType !== "ALL" : selectedProfileType !== mostRecentSearchCriteria.profile_type;
        const selectedSourceUpdated = !mostRecentSearchCriteria.hasOwnProperty("source") ? selectedSource !== "ALL" : selectedSource !== mostRecentSearchCriteria.source;
        const selectedStatusUpdated = !mostRecentSearchCriteria.hasOwnProperty("status") ? selectedStatus !== "ALL" : selectedStatus !== mostRecentSearchCriteria.status;
        const startTimeUpdated = !mostRecentSearchCriteria.hasOwnProperty("start") ? start !== INITIAL_START_MS : Math.floor(start / 1000) !== mostRecentSearchCriteria.start;
        const endTimeUpdated = !mostRecentSearchCriteria.hasOwnProperty("end") ? end !== INITIAL_END_MS : Math.floor(end / 1000) !== mostRecentSearchCriteria.end;

        setIsSearchCriteriaUpdated(airingIdUpdated || mediaIdUpdated || titleIdUpdated || showIdUpdated || videoIdsUpdated || selectedBrandUpdated ||
            selectedProfileTypeUpdated || selectedSourceUpdated || selectedStatusUpdated || startTimeUpdated || endTimeUpdated);
    }, [airingIds, mediaIds, titleIds, showIds, selectedBrand, selectedProfileType, selectedSource, selectedStatus, start, end, JSON.stringify(mostRecentSearchCriteria), searchClicked]);

    const performSearch = () => {
        const brandsToSend = selectedBrand !== "ALL" ? [selectedBrand] : brandLimits !== false ? brandLimits : false;
        console.log(`AdmiralAuditMasterDetailView.performSearch: selected brand? ${selectedBrand}; brand limits? ${JSON.stringify(brandLimits)}; brands to send? ${JSON.stringify(brandsToSend)}`);
        let searchCriteria = {
            ...brandsToSend !== false && {
                brand: {
                    matches_any: true,
                    values: brandsToSend
                }
            },
            ...titleIds && titleIds.length > 0 && {
                title_id: {
                    matches_any: true,
                    values: titleIds
                }
            },
            ...airingIds && airingIds.length > 0 && {
                airing_id: {
                    matches_any: true,
                    values: airingIds
                }
            },
            ...showIds && showIds.length > 0 && {
                show_id: {
                    matches_any: true,
                    values: showIds
                }
            },
            ...mediaIds && mediaIds.length > 0 && {
                media_id: {
                    matches_any: true,
                    values: mediaIds
                }
            },
            ...videoIds && videoIds.length > 0 && {
                video_ids: {
                    matches_any: true,
                    values: videoIds
                }
            },
            ...ingestType && {ingest_type: ingestType},
            ...(selectedStatus && selectedStatus !== "ALL") && {status: selectedStatus},
            ...(selectedProfileType && selectedProfileType !== "ALL") && {profile_type: selectedProfileType},
            ...(selectedSource && selectedSource !== "ALL") && {source: selectedSource},
            ...start && {start: Math.floor(start / 1000)},
            ...end && {end: Math.floor(end / 1000)}
        };

        console.log(searchCriteria);

        setLoading(true);
        return AdmiralDataProvider.getAuditData(searchCriteria).then(response => {
            setCiviData(response.results.map(result => {
                const flattenedResult = {};
                Object.assign(flattenedResult, result.context);
                Object.assign(flattenedResult, result);
                return flattenedResult;
            }));
            setMostRecentSearchCriteria(searchCriteria);
        }).catch(error => {
                console.error(error);
        }).finally(() => {
            setSearchClicked(true);
            setLoading(false);
            return true;
        });
    };

    const clearFilters = () => {
        setSelectedBrand("ALL");
        setSelectedProfileType("ALL");
        setSelectedStatus("ALL");
        setSelectedSource("ALL");
        setMediaIds([]);
        setTitleIds([]);
        setAiringIds([]);
        setShowIds([]);
        setVideoIds([]);
        setStart(INITIAL_START_MS);
        setEnd(INITIAL_END_MS);
    };

    const reprocess = () => {
        setLoading(true);
        setIsSearchCriteriaUpdated(true);
        AdmiralDataProvider.reprocess(start, end, airingIds, selectedBrand, selectedProfileType, mediaIds, showIds, selectedSource, selectedStatus, titleIds, videoIds).then(response => {
            props.toast("Success", `Successfully reprocessed ${response.count} assets for the selected search criteria.`, "success");
            console.log("AdmiralAuditMasterDetailView.reprocess results: ", response.results);
            setCiviData(response.results.map(result => {
                const flattenedResult = {};
                Object.assign(flattenedResult, result.context);
                Object.assign(flattenedResult, result);
                return flattenedResult;
            }));
        }).catch(error => {
            props.toast("Error", "Error reprocessing the selected search criteria", "error");
            console.error("(AdmiralAuditMasterDetailView.reprocess) error reprocessing search criteria: ", mostRecentSearchCriteria, error);
        }).finally(() => {
            setLoading(false);
            setSearchClicked(false);
        });
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={3} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "hidden"}}>
                    <Form widths={16}>
                        <Form.Field>
                            <Button color="blue" onClick={performSearch} fluid>Search</Button>
                        </Form.Field>
                        <Form.Field>
                            <Form.Field>
                                <Button fluid color="black" onClick={clearFilters}>Clear Filters</Button>
                            </Form.Field>
                        </Form.Field>
                        {
                            userCanReprocess ?
                                <Form.Field>
                                    <ContingentButton
                                        fluid
                                        color="red"
                                        disabled={shouldDisableReprocess}
                                        onClick={reprocess}
                                        service={props.service}
                                        module={props.module}
                                        scope={reprocessableBrands}
                                        allPermissions={props.permissions}
                                        user={props.user}
                                    >Reprocess</ContingentButton>
                                </Form.Field> : ""
                        }
                        <Form.Field
                            label="Airing ID"
                            control={Select}
                            multiple
                            search
                            allowAdditions
                            options={airingIdOptions}
                            value={airingIds}
                            onChange={(event, {value}) => setAiringIds(value)}
                            onAddItem={(event, {value}) => setAiringIdOptions(airingIdOptions.concat({key: value, text: value, value}))}
                        />
                        <Form.Field
                            label="Title ID"
                            control={Select}
                            multiple
                            search
                            allowAdditions
                            options={titleIdOptions}
                            value={titleIds}
                            onChange={(event, {value}) => setTitleIds(value)}
                            onAddItem={(event, {value}) => setTitleIdOptions(titleIdOptions.concat({key: value, text: value, value}))}
                        />
                        <Form.Field
                            label="Video ID"
                            control={Select}
                            multiple
                            search
                            allowAdditions
                            options={videoIdOptions}
                            value={videoIds}
                            onChange={(event, {value}) => setVideoIds(value)}
                            onAddItem={(event, {value}) => setVideoIdOptions(videoIdOptions.concat({key: value, text: value, value}))}
                        />
                        <Form.Field
                            label="Media ID"
                            control={Select}
                            options={mediaIdOptions}
                            multiple
                            search
                            allowAdditions
                            value={mediaIds}
                            onChange={(event, {value}) => setMediaIds(value)}
                            onAddItem={(event, {value}) => setMediaIdOptions(mediaIdOptions.concat({key: value, text: value, value}))}
                        />
                        <Form.Field
                            label="Show ID"
                            control={Select}
                            options={showIdOptions}
                            multiple
                            search
                            allowAdditions
                            value={showIds}
                            onChange={(event, {value}) => setShowIds(value)}
                            onAddItem={(event, {value}) => setShowIdOptions(showIdOptions.concat({key: value, text: value, value}))}
                        />
                        <Form.Field
                            control={Select}
                            search
                            label="Profile Type"
                            value={selectedProfileType}
                            onChange={(event, {value}) => setSelectedProfileType(value)}
                            options={profileTypeOptions}
                        />
                        <Form.Field
                            control={Select}
                            search
                            label="Status"
                            value={selectedStatus}
                            onChange={(event, {value}) => setSelectedStatus(value)}
                            options={STATUS_OPTIONS}
                        />
                        <Form.Field
                            label="Brand"
                            control={Select}
                            search
                            options={brandOptions}
                            value={selectedBrand}
                            onChange={(event, {value}) => setSelectedBrand(value)}
                        />
                        <Form.Field
                            control={Select}
                            search
                            label="Source"
                            value={selectedSource}
                            options={sourceOptions}
                            onChange={(event, {value}) => setSelectedSource(value)}
                        />
                        <Form.Field>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <label>Start Time</label>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    id="start"
                                    value={start}
                                    onChange={setStart}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Field>
                        <Form.Field width={16}>
                            <label>End Time</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    id="end"
                                    value={end}
                                    onChange={setEnd}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={13} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "auto"}}>
                    <Grid.Row>
                        <ReactTable
                            columns={[
                                {
                                    Header: "Brand",
                                    accessor: "brand",
                                    Cell: row => metadata.hasOwnProperty("ingest_brands") && metadata.ingest_brands.hasOwnProperty(row.value) ?
                                        metadata.ingest_brands[row.value] : row.value,
                                    filterMethod: ((filter, rows) => {
                                        const cellValue = metadata.hasOwnProperty("ingest_brands") && metadata.ingest_brands.hasOwnProperty(rows[filter.id]) ?
                                            metadata.ingest_brands[rows[filter.id]] : rows[filter.id];
                                        return cellValue.toString().toLowerCase().includes(filter.value.toLowerCase());
                                    })
                                },
                                {
                                    Header: "Type",
                                    accessor: "profile_type",
                                    Cell: row => metadata.hasOwnProperty("ingest_profiles") && metadata.ingest_profiles.hasOwnProperty(row.value) ?
                                        metadata.ingest_profiles[row.value] : row.value,
                                    filterMethod: (filter, rows) => {
                                        const cellValue = metadata.hasOwnProperty("ingest_profiles") && metadata.ingest_profiles.hasOwnProperty(rows[filter.id]) ?
                                            metadata.ingest_profiles[rows[filter.id]] : rows[filter.id];
                                        return cellValue.toString().toLowerCase().includes(filter.value.toLowerCase());
                                    }
                                },
                                {
                                    Header: "Source",
                                    accessor: "source",
                                    Cell: row => metadata.hasOwnProperty("ingest_sources") && metadata.ingest_sources.hasOwnProperty(row.value) ?
                                        metadata.ingest_sources[row.value] : row.value,
                                    filterMethod: (filter, rows) => {
                                        const cellValue = metadata.hasOwnProperty("ingest_sources") && metadata.ingest_sources.hasOwnProperty(rows[filter.id]) ?
                                            metadata.ingest_sources[rows[filter.id]] : rows[filter.id];
                                        return cellValue.toString().toLowerCase().includes(filter.value.toLowerCase());
                                    }
                                },
                                {Header: "Airing Id", accessor: "airing_id"},
                                {Header: "Media Id", accessor: "media_id"},
                                {Header: "Show Id", accessor: "show_id"},
                                {Header: "Title Id", accessor: "title_id"},
                                {Header: "Video Id", accessor: "video_ids",
                                    Cell: row => {
                                        return <span>{Array.isArray(row.original.video_ids) ? row.original.video_ids.join(", ") : row.original.video_ids}</span>;
                                    }
                                },
                                {Header: "Status", accessor: "status"},
                                {
                                    Header: "Last Updated",
                                    accessor: "last_updated",
                                    filterable: false,
                                    defaultSort:"desc",
                                    Cell: row => {
                                        return <span>{new Date(row.original.last_updated * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>;
                                    }
                                }
                            ]}
                            data={civiData}
                            loading={loading}
                            filterable={true}
                            defaultSorted={[
                                {id: "last_updated", desc: true}
                            ]}
                            SubComponent={row => <AuditSubComponent id={row.original._id} message={row.original.message} context={row.original.context} payload={row.original.payload} status={row.original.status} toast={props.toast} />}
                            defaultPageSize={50}
                            onPageChange={() => {
                                document.getElementsByClassName("rt-thead")[0].scrollIntoView();
                            }}
                        />
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
