import React, {useState, useEffect} from "react";
import {Grid, Message, Icon, Table, Container, Button, Input, Form} from "semantic-ui-react";
import AdmiralGroupsBrandSelector from "./AdmiralGroupsBrandSelector";
import AdmiralDataProvider from "../../../Services/AdmiralDataProvider";
import ContingentButton from "../../ContingentButton";

AdmiralDataProvider.init({baseURL: process.env.ADMIRAL_URL, readOnlyURL: process.env.ADMIRAL_READONLY_URL});

export default function AdmiralGroupsMasterDetailView(props) {
    const [metadata, setMetadata] = useState({});
    const [brands, setBrands] = useState([]);
    const [brandIsReadOnly, setBrandIsReadOnly] = useState(false);
    const [allGroups, setAllGroups] = useState([]);
    const [brandGroups, setBrandGroups] = useState([]);
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [newGroup, setNewGroup] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [updatedSelectedGroupValue, setUpdatedSelectedGroupValue] = useState("");
    const [filter, setFilter] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [loadingGroups, setLoadingGroups] = useState(false);
    const [savingGroup, setSavingGroup] = useState(false);
    const [savingGroupsSuccessMessage, setSavingGroupsSuccessMessage] = useState("");
    const [savingGroupsErrorMessage, setSavingGroupsErrorMessage] = useState("");
    const [loadingGroupsErrorMessage, setLoadingGroupsErrorMessage] = useState("");
    const [readOnlyBrands, setReadOnlyBrands] = useState([]);

    useEffect(function init() {
        refreshMetadata();
        refreshGroups();
    }, []);

    useEffect(function initBrands() {
        let userCanEditAll = false;
        let userCanReadAll = false;
        const updatedEditableBrands = new Set();
        const updatedReadOnlyBrands = new Set();

        if (props.userPermissions) {
            for (const permission of props.userPermissions) {
                const userHasAspenPower = permission.urn === "urn:all:aspen-power";
                const serviceMatches = permission.service === props.service;
                const moduleMatches = permission.module === props.module;
                const userIsEditor = permission.role === "editor";

                if (userHasAspenPower) {
                    userCanEditAll = true;
                    userCanReadAll = true;
                    break;
                }

                if (serviceMatches && moduleMatches) {
                    console.log("AdmiralGroupsMasterDetailView.initBrands: scope? ", permission.scope, "role? ", permission.role);
                    if (permission.scope === "all") {
                        userCanReadAll = true;
                        if (userIsEditor) {
                            userCanEditAll = true;
                        }
                        break;
                    } else {
                        if (userIsEditor) {
                            updatedEditableBrands.add(permission.scope);
                        } else {
                            updatedReadOnlyBrands.add(permission.scope);
                        }
                    }
                }
            }
        }

        const updatedBrands = [];
        if (metadata.hasOwnProperty("ingest_brands")) {
            for (const brand of Object.keys(metadata.ingest_brands)) {
                if (!updatedEditableBrands.has(brand) && userCanReadAll && !userCanEditAll) {
                    updatedReadOnlyBrands.add(brand);
                }

                if (userCanEditAll || userCanReadAll || updatedReadOnlyBrands.has(brand) || updatedEditableBrands.has(brand)) {
                    updatedBrands.push({id: brand, text: metadata.ingest_brands[brand]});
                }
            }
        }
        console.log("AdmiralGroupsMasterDetailView.initBrands: read only brands? ", updatedReadOnlyBrands, "all brands? ", updatedBrands);
        setBrands(updatedBrands);
        setReadOnlyBrands(Array.from(updatedReadOnlyBrands));
    }, [JSON.stringify(metadata), JSON.stringify(props.userPermissions)]);

    useEffect(function updateOnBrandSelect() {
        setSelectedGroup("");
        setUpdatedSelectedGroupValue("");
        setFilter("");
        console.log("AdmiralGroupsMasterDetailView.updateOnGroupSelect: selected group? ", selectedBrand);
    }, [selectedBrand]);

    useEffect(function updateIsBrandReadOnly() {
        if (selectedBrand && Array.isArray(readOnlyBrands)) {
            setBrandIsReadOnly(readOnlyBrands.includes(selectedBrand));
        }
    }, [selectedBrand, JSON.stringify(readOnlyBrands)]);

    useEffect(function updateOnGroupSelect() {
        setUpdatedSelectedGroupValue("");
        setSavingGroupsSuccessMessage("");
        setSavingGroupsErrorMessage("");
    }, [selectedGroup]);

    useEffect(function updateGroupsForBrand() {
        let updatedBrandGroups = [];
        if (selectedBrand) {
            for (const groupsForBrand of allGroups) {
                console.log("groups for brand: ", groupsForBrand);
                if (groupsForBrand.brand === selectedBrand) {
                    updatedBrandGroups = groupsForBrand.groups;
                    break;
                }
            }
        }
        setBrandGroups(updatedBrandGroups);
    }, [JSON.stringify(allGroups), selectedBrand]);

    useEffect(function updateFilterGroups() {
        const updatedFilterGroups = [];
        for (const group of brandGroups) {
            console.log(group);
            if (group.toLowerCase().includes(filter.toLowerCase())) {
                updatedFilterGroups.push(group);
            }
        }
        setFilteredGroups(updatedFilterGroups);
    }, [JSON.stringify(brandGroups), filter]);

    const refreshMetadata = () => {
        AdmiralDataProvider.getMetadata().then(response => {
            if (response && response.hasOwnProperty("message") && response.message.toLowerCase() === "success") {
                setMetadata(response.metadata);
            } else {
                setMetadata({});
            }
        });
    }

    const refreshGroups = () => {
        setLoadingGroups(true);
        setLoadingGroupsErrorMessage("");
        AdmiralDataProvider.getGroups().then(response => {
            if (Array.isArray(response)) {
                setAllGroups(response);
            }
        }).catch(error => {
            setLoadingGroupsErrorMessage("There was an error retrieving groups from Admiral.");
            console.error(error);
        }).finally(() => {
            setLoadingGroups(false);
        });
    };

    const saveSelectedGroup = () => {
        setSavingGroup(true);
        setLoadingGroupsErrorMessage("");
        setSavingGroupsSuccessMessage("");
        setSavingGroupsErrorMessage("");
        AdmiralDataProvider.updateGroup(selectedBrand, selectedGroup, updatedSelectedGroupValue).then(response => {
            if (response.status === "error") {
                setSavingGroupsErrorMessage(`There was an error saving the group ${selectedGroup} to the brand ${selectedBrand}: ${response.message}`);
            } else {
                setSavingGroupsSuccessMessage(`The group ${selectedGroup} has successfully been updated to ${updatedSelectedGroupValue} for the brand ${selectedBrand}.`);
                refreshGroups();
            }
            console.log("update group: ", response);
        }).catch(error => {
            console.error(error);
            setSavingGroupsSuccessMessage(`There was an error saving the group ${selectedGroup} to the brand ${selectedBrand}.`);
        }).finally(() => {
            setSavingGroup(false);
            setUpdatedSelectedGroupValue("");
        });
    };

    const addGroup = () => {
        setSavingGroup(true);
        setSelectedGroup(newGroup);
        setLoadingGroupsErrorMessage("");
        setSavingGroupsSuccessMessage("");
        setSavingGroupsErrorMessage("");
        AdmiralDataProvider.addGroup(selectedBrand, newGroup).then(response => {
            if (response.status === "error") {
                setSavingGroupsErrorMessage(`There was an error saving the group ${selectedGroup} to the brand ${selectedBrand}: ${response.message}`);
            } else {
                setSavingGroupsSuccessMessage(`The group ${newGroup} has successfully been added to the brand ${selectedBrand}.`);
                refreshGroups();
            }
            console.log("add group: ", response);
        }).catch(error => {
            console.error(error);
            setSavingGroupsSuccessMessage(`There was an error saving the group ${selectedGroup} to the brand ${selectedBrand}.`);
        }).finally(() => {
            setSavingGroup(false);
            setNewGroup("");
            setUpdatedSelectedGroupValue("");
        });
    };

    const deleteSelectedGroup = () => {
        setSavingGroup(true);
        setLoadingGroupsErrorMessage("");
        setSavingGroupsSuccessMessage("");
        setSavingGroupsErrorMessage("");
        AdmiralDataProvider.deleteGroup(selectedBrand, selectedGroup).then(response => {
            if (response.status === "error") {
                setSavingGroupsErrorMessage(`There was an error deleting the group ${selectedGroup} from the brand ${selectedBrand}: ${response.message}`);
            } else {
                setSavingGroupsSuccessMessage(`The group ${selectedGroup} was successfully deleted from the brand ${selectedBrand}.`);
                refreshGroups();
            }
        }).catch(error => {
            console.error(error);
            setSavingGroupsSuccessMessage(`There was an error deleting the group ${selectedGroup} from the brand ${selectedBrand}.`);
        }).finally(() => {
            setSavingGroup(false);
        });
    };

    return (
        <Grid className="masterDetailContainer">
            <Grid.Row className="masterContainer">
                <Grid.Column width={3}>
                    <AdmiralGroupsBrandSelector
                        brands={brands}
                        selectedBrand={selectedBrand}
                        setSelectedBrand={setSelectedBrand}
                        loadingGroups={loadingGroups}
                        loadingGroupsErrorMessage={loadingGroupsErrorMessage}
                        refreshGroups={refreshGroups}
                    />
                </Grid.Column>
                <Grid.Column width={13}>
                    {
                        loadingGroups && !savingGroup ?
                            <Message icon>
                                <Icon name="spinner" loading />
                                <Message.Content>
                                    Loading groups...
                                </Message.Content>
                            </Message> :
                        loadingGroupsErrorMessage.length > 0 ?
                            <Message error icon>
                                <Icon name="exclamation" />
                                <Message.Content>
                                    {loadingGroupsErrorMessage}
                                </Message.Content>
                            </Message> : ""
                    }
                    {
                        loadingGroups && !selectedBrand ? "" :
                        !selectedBrand ?
                            <Message warning icon>
                                <Icon name="caret left" />
                                <Message.Content>
                                    Select a brand from the left to view and modify its groups.
                                </Message.Content>
                            </Message> :
                            <Container fluid>
                                {
                                    savingGroup ?
                                        <Message color="blue" icon>
                                            <Icon name="spinner" loading />
                                            <Message.Content>Saving {selectedGroup} to the brand {selectedBrand}...</Message.Content>
                                        </Message> : ""
                                }
                                {
                                    savingGroupsSuccessMessage ?
                                        <Message color="yellow" icon>
                                            <Icon name="save outline" />
                                            <Message.Content>{savingGroupsSuccessMessage}</Message.Content>
                                        </Message> : ""
                                }
                                {
                                    savingGroupsErrorMessage ?
                                        <Message color="red" icon>
                                            <Icon name="exclamation" />
                                            <Message.Content>{savingGroupsErrorMessage}</Message.Content>
                                        </Message> : ""
                                }
                                {
                                    !brandIsReadOnly ?
                                        <Form>
                                            <Form.Group widths="equal">
                                                <Form.Field
                                                    label="New Group"
                                                    control={Input}
                                                    value={newGroup}
                                                    onChange={(event, {value}) => setNewGroup(value)}
                                                />
                                                <Form.Field width={8}>
                                                    <label>&nbsp;</label>
                                                    <Button color="green" fluid onClick={addGroup} disabled={newGroup.length < 1}>Add Group</Button>
                                                </Form.Field>
                                            </Form.Group>
                                        </Form> : ""
                                }
                                {
                                    brandGroups.length < 1 ?
                                        <Message warning icon>
                                            <Icon name="exclamation" />
                                            <Message.Content>
                                                {metadata.ingest_brands[selectedBrand]} has no groups yet.
                                            </Message.Content>
                                        </Message> :
                                        <Container fluid>
                                            <Table selectable>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell colSpan="2">
                                                            <label>Filter Groups</label>
                                                            <Input
                                                                control={Input}
                                                                value={filter}
                                                                onChange={(event, {value}) => setFilter(value)}
                                                                fluid
                                                            />
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                            </Table>
                                            <Table selectable>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={8}>Group Name</Table.HeaderCell>
                                                        <Table.HeaderCell width={8}>{!brandIsReadOnly ? "Updated Group Name" : ""}</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {
                                                        filteredGroups.map((group, index) =>
                                                            <Table.Row
                                                                onClick={() => setSelectedGroup(group)}
                                                                key={`group-${group}-${index}`}
                                                            >
                                                                <Table.Cell width={8}>{group}</Table.Cell>
                                                                <Table.Cell width={8}>
                                                                    <Form>
                                                                        <Form.Group>
                                                                            {
                                                                                !brandIsReadOnly ?
                                                                                    <Form.Field
                                                                                        label="&nbsp;"
                                                                                        control={Input}
                                                                                        value={group === selectedGroup ? updatedSelectedGroupValue : ""}
                                                                                        onChange={(event, {value}) => setUpdatedSelectedGroupValue(value)}
                                                                                        fluid
                                                                                        disabled={group !== selectedGroup}
                                                                                        width={8}
                                                                                    /> : <Form.Field width={8} />
                                                                            }
                                                                            <Form.Field width={4}>
                                                                                <label>&nbsp;</label>
                                                                                <ContingentButton
                                                                                    primary
                                                                                    disabled={group !== selectedGroup || updatedSelectedGroupValue.length < 1 || brandIsReadOnly}
                                                                                    onClick={saveSelectedGroup}
                                                                                    floated="left"
                                                                                    fluid
                                                                                    service={props.service}
                                                                                    module={props.module}
                                                                                    scope={selectedBrand}
                                                                                    user={props.user}
                                                                                    allPermissions={props.permissions}
                                                                                >Save</ContingentButton>
                                                                            </Form.Field>
                                                                            <Form.Field width={4}>
                                                                                <label>&nbsp;</label>
                                                                                <ContingentButton
                                                                                    color="black"
                                                                                    onClick={deleteSelectedGroup}
                                                                                    floated="right"
                                                                                    fluid
                                                                                    service={props.service}
                                                                                    module={props.module}
                                                                                    scope={selectedBrand}
                                                                                    user={props.user}
                                                                                    allPermissions={props.permissions}
                                                                                >Delete</ContingentButton>
                                                                            </Form.Field>
                                                                        </Form.Group>
                                                                    </Form>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    }
                                                </Table.Body>
                                            </Table>
                                        </Container>

                                }
                            </Container>
                        }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};
