import React, { Component } from "react";
import {Grid, Divider, Button, Container, Input, Header} from "semantic-ui-react";
import AdobeTempPassDataProvider from "../../../Services/AdobeTempPassDataProvider";

export default class AdobeTempPassMasterDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validEnvironments: ["stage", "prod"],
            validBrands: ["TNT", "TBS", "AdultSwim", "CNN", "MML", "truTV", "Bleacher"],
            selectedEnvironment: undefined,
            selectedBrand: undefined,
            deviceId: ""
        };

        AdobeTempPassDataProvider.init(process.env.ADOBE_TEMP_PASS_URL, this.state.validEnvironments, this.state.validBrands);

        this.generateBrandButtonGroup = this.generateBrandButtonGroup.bind(this);
        this.generateEnvironmentButtonGroup = this.generateEnvironmentButtonGroup.bind(this);
        this.changeDeviceId = this.changeDeviceId.bind(this);
        this.changeSelectedBrand = this.changeSelectedBrand.bind(this);
        this.changeSelectedEnvironment = this.changeSelectedEnvironment.bind(this);
        this.submitClicked = this.submitClicked.bind(this);
    }

    async submitClicked() {
        AdobeTempPassDataProvider.submit(this.state.selectedEnvironment, this.state.selectedBrand, this.state.deviceId).then(() => {
            this.props.toast("TempPass Reset Successful", `Device ID ${this.state.deviceId} has been reset for ${this.state.selectedBrand} in the ${this.state.selectedEnvironment} environment.`, "success");
        }).catch(error => {
            console.log(error.toString());
            this.props.toast("Error", error.toString(), "error");
        });
    }

    changeSelectedEnvironment(event, selectedEnvironment) {
        this.setState({selectedEnvironment});
    }

    changeSelectedBrand(event, selectedBrand) {
        this.setState({selectedBrand});
    }

    changeDeviceId(event) {
        this.setState({deviceId: event.target.value});
    }

    generateEnvironmentButtonGroup() {
        return this.state.validEnvironments.map(environment => {
            return (
                <Button onClick={event => this.changeSelectedEnvironment(event, environment)} key={environment} positive={this.state.selectedEnvironment === environment}>{environment}</Button>
            );
        })
    }

    generateBrandButtonGroup() {
        return this.state.validBrands.map(brand => {
            return <Button onClick={event => this.changeSelectedBrand(event, brand)} key={brand} positive={this.state.selectedBrand === brand}>{brand}</Button>
        });
    }

    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Container text textAlign="center">
                            <Divider horizontal>Environment</Divider>
                            <Grid.Row>
                                {this.generateEnvironmentButtonGroup()}
                            </Grid.Row>
                            <Divider horizontal>Brand</Divider>
                            <Grid.Row>
                                {this.generateBrandButtonGroup()}
                            </Grid.Row>
                            <Divider horizontal>Device ID</Divider>
                            <Grid.Row>
                                <Input value={this.state.deviceId} onChange={this.changeDeviceId} />
                            </Grid.Row>
                            <Divider horizontal/>
                            <Grid.Row>
                                <Button onClick={this.submitClicked}>Submit</Button>
                            </Grid.Row>
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
