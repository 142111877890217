import React, {useEffect, useState} from "react";
import {Button, Segment, Tab} from "semantic-ui-react";
const HTML_ENCODED_UNICODE_REGEX = /(&#x[\d\w]+;)/g;
const REPLACE_HTML_ENCODED_ZERO_REGEX = /&#x(?=[\d\w]+;)/;
const UNICODE_REGEX = /(0x[\d\w]+;)/g;

export default function AdmiralAuditMessageTab(props) {
    const [isJSON, setIsJSON] = useState(false);
    const [messages, setMessages] = useState({});

    useEffect(function determineIfJSON() {
        try {
            const messageJSON = JSON.parse(props.message);
            setIsJSON(true);

            const messages = {};
            for (const templateName of Object.keys(messageJSON)) {
                const message = {};
                message.original = messageJSON[templateName];
                message.unicodeFound = message.original.split(HTML_ENCODED_UNICODE_REGEX).length > 1;
                message.unicodeDecoded = false;
                message.decoded = message.original.split(HTML_ENCODED_UNICODE_REGEX).filter(x => x.length > 0)
                    .map(x => x.replace(REPLACE_HTML_ENCODED_ZERO_REGEX, "0x"))
                    .map(x => x.replace(UNICODE_REGEX, match => String.fromCodePoint(parseInt(match)))).join("");
                messages[templateName] = message;
            }
            setMessages(messages);
        } catch (_) {
            setIsJSON(false);
        }
    }, [props.message]);

    const unicodeButtonClicked = templateName => {
        const message = messages[templateName];
        message.unicodeDecoded = !message.unicodeDecoded;
        setMessages(Object.assign({}, messages, {[templateName]: message}));
    }

    const copyToClipboard = (templateName, payload) => {
        navigator.clipboard.writeText(payload).then(() => {
            props.toast("Copied", `You have copied the message for the template ${templateName} to your clipboard.`, "success");
        });
    }

    return (
        ["COMPLETED", "REPROCESS_COMPLETED"].includes(props.status) ?
            <Tab.Pane>
                {
                    isJSON ?
                        <Tab
                            vertical="true"
                            tabular
                            panes={
                                Object.keys(messages).map(templateName => {
                                    const message = messages[templateName];
                                    return {
                                        menuItem: {
                                            key: `audit-message-${templateName}-tab`,
                                            content:
                                            <Segment basic>
                                                <label>
                                                    {`${templateName} ${message.unicodeFound ? "(Unicode Detected!)" : ""}`}
                                                </label>
                                                {
                                                    message.unicodeFound === true ?
                                                        <div>
                                                            <br />
                                                            <Button size="mini" fluid onClick={() => unicodeButtonClicked(templateName)} color={message.unicodeDecoded ? "blue" : "black"}>
                                                                {message.unicodeDecoded ? "Show Original" : "Show Unicode"}
                                                            </Button>
                                                        </div> : ""
                                                }
                                            </Segment>
                                        },
                                        render: () => <Segment>
                                            <Button floated="right" onClick={() => copyToClipboard(templateName, message.unicodeDecoded ? message.decoded : message.original)}>Copy {templateName} Message</Button>
                                            <br />
                                            <pre>{message.unicodeDecoded ? message.decoded : message.original}</pre>
                                        </Segment>
                                    }
                                })
                            }
                        /> :
                        <Segment><pre>{props.message}</pre></Segment>
                }
            </Tab.Pane> :
            <Segment><pre>{props.message}</pre></Segment>
    );
};
